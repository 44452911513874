import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function Tile({title, icon}) {
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '140px',
        padding: '10px',
        // border: '1px solid #989898',
        //                     borderRadius: '4px',
        // width: {
        //     xs: '140px',
        //     // lg: '140px'
        // },
        // height: '130px',
        // padding: '10px',
        justifyContent: 'center',
    }}>
        <img src={`./images/icons/${icon}.svg`} style={{height: '40px', width: '40px'}} alt={icon} />
        <Typography align="center" sx={{
            fontSize: '18px',
            marginTop: '10px',
            fontWeight: 600,
            lineHeight: '21.78px'
        }}>{title}</Typography>
    </Box>
  )
}

export default Tile