import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import ApplyPolicy from './policy/apply-policy';
import ClaimPolicy from './policy/claim-policy';
import ViewPolicy from './policy/view-policy';
import HomePage from './pages/HomePage';
import TrainingModulesPage from './pages/TrainingModulesPage';
import DigitalRiskPage from './pages/DigitalRiskPage';
import DarkWebMonitoringPage from './pages/DarkWebMonitoringPage';
import InsurancePage from './pages/InsurancePage';
export default class AppRoute extends Component {

    beforeLoginRoutes() {
        return (
            <React.Fragment>
                <Route exact path='/' component={HomePage} />
            </React.Fragment>
        );
    }

    afterLoginRoutes() {
        return (
            <React.Fragment>
                <Route exact path='/' component={HomePage} />
                <Route path='/box' component={HomePage} />
                <Route path='/digital_risk_assessment' component={DigitalRiskPage} />
                <Route path='/dark_web_monitoring' component={DarkWebMonitoringPage} />
                <Route path='/insurance' component={InsurancePage} />
                <Route path='/training_modules' component={TrainingModulesPage} />
                <Route path='/new-policy' component={ApplyPolicy} />
                <Route path='/view-policy' component={ViewPolicy} />
                <Route path='/claim-policy' component={ClaimPolicy} />
            </React.Fragment>
        );
    }

    render() {
        return (
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route path='/box' component={HomePage} />
                <Route path='/digital_risk_assessment' component={DigitalRiskPage} />
                <Route path='/dark_web_monitoring' component={DarkWebMonitoringPage} />
                <Route path='/insurance' component={InsurancePage} />
                <Route path='/training_modules' component={TrainingModulesPage} />
                <Route path='/new-policy' component={ApplyPolicy} />
                <Route path='/view-policy' component={ViewPolicy} />
                <Route path='/claim-policy' component={ClaimPolicy} />
            </Switch>
        )
    }
}
