import Box from '@mui/material/Box';
import React from 'react'
import Footer from '../shared/footer/Footer'
import Header from '../shared/header/Header'
import BottomNavBarMobile from '../shared/nav/BottomNavBarMobile';

function DefaultLayout({ children }) {
    return (
        <React.Fragment>
            <Box sx={{
                padding: {
                    xs: '20px 35px 100px 35px',
                    md: '0px'
                },
                backgroundColor: '#272736'
            }}>
                <Header />
                <Box sx={{
                    color: '#fff',
                    padding: {
                        xs: '0px',
                        md: '0px 10px'
                    }
                }}>
                    {children}
                </Box>

                <Footer />
            </Box>
                <BottomNavBarMobile />
        </React.Fragment>
    )
}

export default DefaultLayout