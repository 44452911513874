import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

function createData(
    name: string,
    last_name: string,
    company: string,
    year: number
) {
    return { name, last_name, company, year };
}

const rows = [
    createData('Jan', "KP", "Google", 1999),
    createData('Dan', "SP", "ADOBE", 2000),
    createData('yan', "JJ", "TESLA", 2001),

];
type MyState = {
    isOpen: boolean;
};
export default class ClaimPolicy extends Component<MyState>{
    state: MyState = {
        isOpen: false,
    };

    handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpen: false })
    };
    render() {
        return (
            <React.Fragment>

                <div className="container" style={{ marginTop: '100px' }}>
                    <Typography sx={{ fontSize: 30 }} variant="h3" component="div" color="rgb(6, 27, 48)">
                        Policy List
                    </Typography>

                    <TableContainer component={Paper} style={{backgroundColor: "rgb(237 241 245)"}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Last Name</TableCell>
                                    <TableCell align="right">Year</TableCell>
                                    <TableCell align="right">Company</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>

                                        <TableCell align="right">{row.last_name}</TableCell>
                                        <TableCell align="right">{row.year}</TableCell>
                                        <TableCell align="right">{row.company}</TableCell>
                                        <TableCell align="right">
                                            <button onClick={() => this.setState({ isOpen: true })}  style={{
                                                backgroundColor: "rgb(6 27 48)",
                                            }} className="btn-small">Claim</button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Snackbar
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        autoHideDuration={3000}
                        message="Claim policy success"
                    />
                </div>
            </React.Fragment>
        )
    }
}
