import React, { Component } from 'react'
import { FormControl, InputLabel, Input, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


export default class ApplyPolicy extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            type: 10,
            step: 1,
            website: "",
            amount: "",
            company: "",
            lname: "",
            fname: "",
            email: "",
            phone: "",
            open: false,
            snackText: '',
        }
    }
    handleChange = (event: SelectChangeEvent) => {
        this.setState({ type: event.target.value })
    }
    handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClickButton = () => {
        if (this.state.amount === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Amount is required" })
            return
        }
        else if (this.state.company === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Company is required" })
            return
        }
        else if (this.state.website === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Website is required" })
            return
        }
        else {
            this.setState({ step: 2 })
        }

    }
    onClickBackButton = () => {
        this.setState({ step: 1 })
    }
    onClickSaveButton = (text: string) => {
        if (this.state.email === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Email is required" })
            return
        }
        else if (this.state.phone === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Phone is required" })
            return
        }
        else if (this.state.lname === "") {
            this.setState({ open: true })
            this.setState({ snackText: "Last Name is required" })
            return
        } else if (this.state.fname === "") {
            this.setState({ open: true })
            this.setState({ snackText: "First Name is required" })
            return
        }
        else {
            this.setState({ open: true })
            this.setState({ snackText: text })
            setTimeout(
                () => this.setState({ open: false }),
                3000
            );
            setTimeout(
                () => this.props.history.push("/view-policy"),
                2000
            );
        }
    }
    _handleAmountChange = (e: any) => {
        this.setState({
            amount: e.target.value
        });
    }
    _handleCompanyChange = (e: any) => {
        this.setState({
            company: e.target.value
        });
    }
    _handleWebsiteChange = (e: any) => {
        this.setState({
            website: e.target.value
        });
    }
    _handleFnameChange = (e: any) => {
        this.setState({
            fname: e.target.value
        });
    }
    _handleLnameChange = (e: any) => {
        this.setState({
            lname: e.target.value
        });
    }
    _handlePhoneChange = (e: any) => {
        this.setState({
            phone: e.target.value
        });
    }
    _handleEmailChange = (e: any) => {
        this.setState({
            email: e.target.value
        });
    }
    render() {



        return (
            <React.Fragment>

                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'center'}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ minHeight: '60vh', marginTop: '30px' }}
                >
                    <Grid item xs={3} md={6}>
                        <Paper elevation={3} sx={{ marginTop: '20px', backgroundColor: "rgb(237 241 245)" }} >
                            <Typography sx={{ fontSize: 30, display: 'flex', justifyContent: 'center', marginTop: '20px' }} variant="h3" component="div" color="rgb(6, 27, 48)">
                                Apply Policy
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '12px', marginTop: '12px' }}>
                                <div style={{ marginTop: '20px', display: (this.state.step === 1 ? 'block' : 'none') }}>
                                    <FormControl sx={{ minWidth: { xs: 220, sm: 300, md: 400 } }}>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Type"
                                            value={this.state.type || ""}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value={10}>Medical</MenuItem>
                                            <MenuItem value={20}>Vehicle</MenuItem>
                                            <MenuItem value={30}>Life</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br></br>
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="my-input">Amount *</InputLabel>
                                        <Input onChange={this._handleAmountChange} value={this.state.amount} required id="my-input" aria-describedby="amount" />
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="my-input"> Company name *</InputLabel>
                                        <Input onChange={this._handleCompanyChange} value={this.state.company} required id="company" aria-describedby="company" />
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="website"> Website *</InputLabel>
                                        <Input onChange={this._handleWebsiteChange} value={this.state.website} required id="website" aria-describedby="website" />
                                    </FormControl>

                                    <br /><br />
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <button onClick={() => this.onClickButton()} id="download-button" style={{
                                            backgroundColor: "rgb(6 27 48)",
                                        }} className="btn-small">Next</button>
                                    </div>
                                </div>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: (this.state.step === 2 ? 'block' : 'none') }}>

                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="first-name">First Name *</InputLabel>
                                        <Input onChange={this._handleFnameChange} value={this.state.fname} required id="fname" aria-describedby="First Name" />
                                    </FormControl>

                                    <br></br><br></br>
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="last-name"> Last name *</InputLabel>
                                        <Input onChange={this._handleLnameChange} value={this.state.lname} required id="lname" aria-describedby="Last Name" />
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="email"> Email *</InputLabel>
                                        <Input onChange={this._handleEmailChange} value={this.state.email} required id="email" aria-describedby="Email" />
                                    </FormControl>

                                    <br /><br />
                                    <FormControl sx={{ minWidth: { xs: 200, sm: 300, md: 400 } }}>
                                        <InputLabel htmlFor="phone"> Phone *</InputLabel>
                                        <Input onChange={this._handlePhoneChange} value={this.state.phone} required id="phone" aria-describedby="Phone" />
                                    </FormControl>
                                    <br></br> <br></br>
                                    <div style={{ marginBottom: '6px', display: 'flex', justifyContent: 'end' }}>
                                        <button onClick={() => this.onClickBackButton()} id="download-button" style={{
                                            backgroundColor: "rgb(6 27 48)",
                                        }} className="btn-small">Back</button>
                                        <button onClick={() => this.onClickSaveButton('saved')} id="download-button" style={{
                                            backgroundColor: "rgb(6 27 48)", marginLeft: '3px'
                                        }} className="btn-small">Save</button>
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={this.state.open}
                    autoHideDuration={1000}
                    onClose={this.handleClose}
                    message={this.state.snackText}
                />
            </React.Fragment >
        )
    }

}