import { useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import FlareRoundedIcon from '@mui/icons-material/FlareRounded';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

function BottomNavBarMobile() {
    const [value, setValue] = useState(0);
  return (
    <Box sx={{   display: {xs: 'block', md: 'none'}, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
            background: '#545454',
            color: '#F4F4F4',
            padding: '0px 10px'
        }}
      >
        <BottomNavigationAction style={{color: '#F4F4F4'}}  label="Home" icon={<HomeOutlinedIcon style={{color: '#F4F4F4'}} />} />
        <BottomNavigationAction style={{color: '#F4F4F4'}} label="Pay on Credit" icon={<CreditCardOutlinedIcon style={{color: '#F4F4F4'}} />} />
        <BottomNavigationAction style={{color: '#F4F4F4'}} label="Home Services" icon={<KeyboardArrowUpOutlinedIcon style={{color: '#F4F4F4'}} />} />
        <BottomNavigationAction style={{color: '#F4F4F4'}} label="Premium" icon={<FlareRoundedIcon style={{color: '#F4F4F4'}} />} />
        <BottomNavigationAction style={{color: '#F4F4F4'}} label="Saved" icon={<FavoriteBorderOutlinedIcon style={{color: '#F4F4F4'}} />} />
      </BottomNavigation>
    </Box>
  )
}

export default BottomNavBarMobile