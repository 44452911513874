import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Link } from 'react-router-dom';

function ViewInsuranceCoverageDetails() {
  return (
    <Box sx={{
        paddingTop: {xs: '10px', md: '3rem !important'}, 
        paddingBottom: '20px',
        borderBottom: '0.1rem solid #42424f'
        }}>
        <Link to="/">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{
                    color: {
                        xs: '#DDDDDD',
                        md: '#fff'
                    },
                }}>View insurance coverage details</Typography>
                <ArrowCircleRightOutlinedIcon style={{ color: 'ffbf16', marginLeft: '10px' }} />
            </div>
        </Link>
    </Box>
  )
}

export default ViewInsuranceCoverageDetails