import { Box, Typography } from '@mui/material'
import React from 'react'
import LogoWithBackButton from '../shared/common/LogoWithBackButton'
import ViewInsuranceCoverageDetails from '../shared/common/ViewInsuranceCoverageDetails'
import DefaultLayout from './DefaultLayout'
import GridCenteredLayout from './GridCenteredLayout'

function BoxModuleLayout({ children, title }) {
    return (
        <DefaultLayout>
            <LogoWithBackButton />
            <ViewInsuranceCoverageDetails />
            <Box sx={{
                padding: '50px 0px'
            }}>
                <Typography align='center'>{title}</Typography>
            </Box>
            <GridCenteredLayout>

                {children}
            </GridCenteredLayout>
        </DefaultLayout>
    )
}

export default BoxModuleLayout