import React from 'react'
import BoxModuleLayout from '../layout/BoxModuleLayout'

function TrainingModulesPage() {

    const modules = [100, 0, 0].map((percent, index) => (
        <div>
            <div style={{
                padding: '10px 20px',
                fontSize: '14px',
                fontWeight: 500,
                width: '190px',
                height: '45px',
                border: '2px solid #FFBE3D',
                borderRadius: '12px'
            }}>TRAINING MODULE {index + 1}</div>
            <div style={{color: percent === 100 ? '#8CD7AC' : '', padding: '10px', fontSize: '11px'}}>{percent}% COMPLETE</div>
        </div>
    ))
  return (
    <BoxModuleLayout title={'Training & Education'}>
        {modules}
    </BoxModuleLayout>
  )
}

export default TrainingModulesPage