import React from 'react'
import Logo from '../logo/Logo'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

function Footer() {
    return (
        // <footer style=>
        <Box sx={{
            display: {
                xs: 'none',
                md: 'block'
            },
            paddingTop: '10px',
            borderTop: '0.1rem solid #ffffff',
            backgroundColor: '#272736',
            color: '#93939b',
            fontFamily: 'Inter !important'
        }}>
            <Grid sx={{ flexGrow: 1 }} justifyContent="center" container>
                <Grid item xs={12} md={6}>
                    <Logo />
                    <Grid container justifyContent="center">
                        <Typography sx={{
                            color: '#93939b !important'
                        }}>BOXX doesn’t just INSURE businesses and individuals against cyber liability; we offer complete protection against cyber risk. Our mission is to provide the most effective combination of cyber threat prediction, prevention and cyber insurance coverage for small businesses and homes.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        // </footer>
    )
}

export default Footer