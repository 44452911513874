import Box from '@mui/material/Box';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

import Logo from '../logo/Logo';
import { Link } from 'react-router-dom';


function LogoWithBackButton() {
  return (
    <Box sx={{
        display: {
            xs: 'block',
            md: 'none'
        },
        
    }}>
        <Link to="/" style={{display: 'flex', alignItems: 'center', color: '#989898', marginBottom: '10px'}}>
            <KeyboardArrowLeftOutlinedIcon />Back
        </Link>
        <Logo />
    </Box>
  )
}

export default LogoWithBackButton