import React from 'react'
import BoxModuleLayout from '../layout/BoxModuleLayout'
import Search from '../shared/common/Search'

function DarkWebMonitoringPage() {
  return (
    <BoxModuleLayout title={'Dark Web Monitoring'}>
        <div>
            <Search />
        </div>
    </BoxModuleLayout>
  )
}

export default DarkWebMonitoringPage