import React from 'react'
import BoxModuleLayout from '../layout/BoxModuleLayout'
import Search from '../shared/common/Search'

function DigitalRiskPage() {
  return (
    <BoxModuleLayout title="Digital Risk Assessment">
        <Search />
    </BoxModuleLayout>
  )
}

export default DigitalRiskPage