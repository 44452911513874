import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Logo from '../logo/Logo';
import { Typography } from '@mui/material';

function Header() {
    // const logout = () => {
    //     localStorage.clear();
    //     window.location.replace('/');
    // }
    const navItems = ['View policy', 'Apply policy', 'Claim policy'];
    return (
        <AppBar position='sticky' component="nav" 
            sx={{ 
                backgroundColor: '#272736',
                borderBottom: '0.1rem solid #42424f',
                boxShadow: 'none',
                height: '76px',
                display: {
                    xs: 'none',
                    md: 'flex' 
                },
                justifyContent: 'center' 
            }}>
            <Toolbar>
                <Box
                    sx={{
                        flexGrow: 1,
                        height: '76px'
                    }}>
                    <Logo />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {navItems.map((item) => (
                        <Button key={item} sx={{ color: '#fff', textTransform: 'none' }}>
                            <Typography>{item}</Typography>
                        </Button>
                    ))}
                </Box>
            </Toolbar>

        </AppBar>
    )
}

export default Header