import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                {/* <footer className="page-footer orange">
                    <div className="footer-copyright">
                        <div className="container" style={{display: "flex" ,justifyContent: 'center' }}>
                            Copyright © 2022 
                        </div>
                    </div>
                </footer> */}

            </React.Fragment>
        )
    }
}
