import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../../src/boxx_logo.svg'

function Logo() {
    return (
        <Link to="/">
            <img src={logo} height="30px" style={{ width: '150px', height: '76px', objectFit: 'cover' }}
                alt="logo" />
        </Link>
    )
}

export default Logo