import { Box, Button } from '@mui/material'
import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function Search() {
    return (
        <React.Fragment>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #D6D6D9',
                borderRadius: '9px',
                height: '36px',
                padding: '8px 10px'
            }}>

                <SearchOutlinedIcon />
                <input style={{marginBottom: '4px'}} type="search" placeholder='Enter your email or phone number' />
            </div>
            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '20px'

            }}>
                <Button sx={{
                    background: '#FFBE3D',
                    borderRadius: '8px',
                    color: '#2D2D39',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    width: '100px',
                    height: '32px',
                    textTransform: 'none',
                    padding: '0px 20px'
                }}>
                    Search
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default Search