import React from 'react'
import BoxModuleLayout from '../layout/BoxModuleLayout'
import Search from '../shared/common/Search'

function InsurancePage() {
  return (
    <BoxModuleLayout title={'Insurance'}>
      <Search />
    </BoxModuleLayout>
  )
}

export default InsurancePage