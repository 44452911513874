import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tile from '../tile/Tile';
import ViewInsuranceCoverageDetails from '../common/ViewInsuranceCoverageDetails';
import LogoWithBackButton from '../common/LogoWithBackButton';
import { Link } from 'react-router-dom';

function MainContent() {
    return (
        <React.Fragment>
            <LogoWithBackButton />
            <Grid sx={{
                flexGrow: 1,
                padding: {
                    xs: '0px',
                    md: '100px'
                },
                // paddingBottom: '100px'
            }} justifyContent="center" container>

                <Grid item xs={12} md={8} lg={6}>
                    <Grid container sx={{
                        justifyContent: {
                            xs: 'start',
                            md: 'center'
                        },
                        // padding: '100px 0px'
                    }} justifyContent="center">

                        <Typography component={'h1'} sx={{
                            margin: '10px 0 0 0',
                            fontSize: '30px',
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}>BOXX Insurance</Typography>
                        <Typography sx={{
                            color: '#93939b !important',
                            textAlign: {
                                xs: 'left',
                                md: 'center'
                            }
                        }}>BOXX doesn’t just INSURE businesses and individuals against cyber liability; we offer complete protection against cyber risk. Our mission is to provide the most effective combination of cyber threat prediction, prevention and cyber insurance coverage for small businesses and homes.</Typography>
                        <ViewInsuranceCoverageDetails />
                        <Grid item container sx={{ marginTop: '3rem' }} spacing={2}>
                            <Grid item xs={6} md={6} lg={3} sx={{
                            }}>
                                <Link to="/digital_risk_assessment">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        marginTop: '10px',
                                        border: '1px solid #989898',
                                        borderRadius: '4px',
                                        color: '#93939b'
                                    }}
                                >
                                    <Tile title={'Digital Risk Assessment'} icon={'search'} />
                                </Box>
                                </Link>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3} sx={{
                                // border: '1px solid #989898',
                                // borderRadius: '4px'
                            }}>
                                <Link to="/training_modules">
                                    <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        marginTop: '10px',
                                        border: '1px solid #989898',
                                        borderRadius: '4px',
                                        color: '#93939b'
                                    }}
                                >
                                    <Tile title={'Training & Education'} icon={'training'} />
                                </Box>
                                </Link>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3} sx={{
                                // border: '1px solid #989898',
                                // borderRadius: '4px'
                            }}>
                                <Link to="/dark_web_monitoring">
                                    <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        marginTop: '10px',
                                        border: '1px solid #989898',
                                        borderRadius: '4px',
                                        color: '#93939b'
                                    }}
                                >
                                    <Tile title={'Dark Web Monitoring'} icon={'darkweb'} />
                                </Box>
                                </Link>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3} sx={{
                                // border: '1px solid #989898',
                                // borderRadius: '4px'
                            }}>
                                <Link to="/insurance">
                                    <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        marginTop: '10px',
                                        border: '1px solid #989898',
                                        borderRadius: '4px',
                                        color: '#93939b'
                                    }}
                                >
                                    <Tile title={'Insurance '} icon={'insurance'} />
                                </Box>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default MainContent