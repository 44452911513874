import React from 'react'
import DefaultLayout from '../layout/DefaultLayout'
import MainContent from '../shared/content/MainContent'

function HomePage() {
  return (
    <DefaultLayout>
        <MainContent />
    </DefaultLayout>
  )
}

export default HomePage