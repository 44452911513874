import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Common from './component/Common';

function App() {
  return (
   <React.Fragment>
     <Common/>
   </React.Fragment>
  );
}

export default App;
