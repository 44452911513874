import { Grid } from '@mui/material'
import React from 'react'

function GridCenteredLayout({children}) {
    return (
        <Grid sx={{
            flexGrow: 1,
            padding: {
                xs: '0px',
                md: '0px 0px 50px 0px'
            },
        }} justifyContent="center" container>

            <Grid item xs={12} md={8} lg={6}>
                    {children}
            </Grid>
        </Grid>
    )
}

export default GridCenteredLayout